import React, { useState } from "react";
import { Icon } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import SocialMediaLink from "./SocialMediaLink";
import Logo from "../assets/jlogo.png";
import FacebookLogo from ".././assets/fblogo.png";
import TikTokLogo from ".././assets/tiktok.png";
import XLogo from ".././assets/xlogo.png";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToFAQ = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    const faqSection = document.getElementById('faq');
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="bg-white fixed top-0 left-0 right-0 z-10">
      <div className="px-2 sm:px-2 lg:px-6">
        <div className="flex justify-between h-32">
          <div className="flex flex-wrap justify-between items-center">
            <a href="/" className="flex items-center">
              <img className="h-44 w-auto object-cover" src={Logo} alt="Jusaeaze Logo" />
              <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white"></span>
            </a>
          </div>

          <div className="flex items-center ml-auto md:mr-0 gap-8">
            <div className="hidden flex-row md:block">
              <a
                href="#faq"
                onClick={scrollToFAQ} // Add the onClick event
                className="px-3 py-4 text-navy font-medium hover:text-amber transition duration-150 ease-in-out"
              >
                <Icon component={InfoOutlined} className="mr-1 text-navy hover:text-amber" />
                FAQ
              </a>
            </div>

            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex md:hidden items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              aria-label="Open main menu"
              aria-expanded={isMenuOpen}
            >
              {isMenuOpen ? (
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>

            {isMenuOpen && (
              <div className="md:hidden absolute h-screen z-50 top-16 left-0 right-0 bg-navy shadow-lg pb-12 rounded-b-2xl">
                <div className="pt-2 flex text-white text-xl flex-col">
                  <a
                    href="/faq"
                    onClick={scrollToFAQ} // Add the onClick event
                    className="flex items-center justify-start px-3 py-2 font-medium hover:text-amber transition duration-150 ease-in-out"
                  >
                    FAQ
                  </a>
                  <a
                    href="https://wa.me/27681573844"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-start p-2 font-medium hover:text-amber rounded-full transition duration-150 ease-in-out"
                  >
                    Whatsapp
                  </a>
                  <a
                    href="tel:+27681573844"
                    className="flex items-center justify-start p-2 text font-medium hover:text-amber rounded-full transition duration-150 ease-in-out"
                  >
                    Call Us
                  </a>
                  <div className="flex justify-center fixed bottom-0 left-0 right-0 p-4 space-x-4">
                    <SocialMediaLink
                      href="https://www.facebook.com/profile.php?id=61550738293751"
                      logoSrc={FacebookLogo}
                      altText="Facebook Logo"
                    />
                    <SocialMediaLink
                      href="https://www.tiktok.com/@jusamusha"
                      logoSrc={TikTokLogo}
                      altText="TikTok Logo"
                    />
                    <SocialMediaLink
                      href="https://twitter.com/JusaMusha"
                      logoSrc={XLogo}
                      altText="X Logo"
                    />
                  </div>
                </div>
              </div>
            )}

            <style jsx>{`
              @media (max-width: 767px) {
                .hidden-mobile {
                  display: none;
                }
              }
            `}</style>

            <div className="hidden-mobile flex items-center">
              <div className="ml-auto">
                <a
                  href="https://wa.me/27681573844"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center p-2 rounded-full transition duration-150 ease-in-out"
                >
                  <i className="fab fa-whatsapp text-navy text-xl hover:text-amber"></i>
                  <span className="text-navy ml-2 hover:text-amber">WhatsApp</span>
                </a>
              </div>
              <a
                href="tel:+27681573844"
                className="flex items-center justify-center p-2 rounded-full transition duration-150 ease-in-out"
              >
                <i className="fas fa-phone-alt text-l rounded-full text-navy hover:text-amber"></i>
                <span className="text-navy ml-2 hover:text-amber">+27681573844</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
