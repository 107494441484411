import { HashRouter, Routes, Route } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from './components/Home';
import SolutionHeading from "./components/SolutionHeading";
import SolutionTwo from "./components/SolutionTwo";
import Footer from './components/Footer';
import FAQ from "./components/FAQ";
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Faq from './components/FAQ';

function App() {
  return (
    <div>
      <HashRouter>
        <Navbar />
        <div className='py-20 mb-10'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/solution_heading" element={<SolutionHeading />} />
            <Route path="/solution_two" element={<SolutionTwo />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          </Routes>
        </div>
        <Footer />

      </HashRouter>

    </div >
  );
}

export default App;
