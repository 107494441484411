import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Logo from ".././assets/logo.png";
import FacebookLogo from ".././assets/fblogo.png";
import TikTokLogo from ".././assets/tiktok.png";
import XLogo from ".././assets/xlogo.png";
import SocialMediaLink from "./SocialMediaLink";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };

  return (
    <footer className="bg-navy rounded-t-3xl">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a href="/" className="flex items-center mb-4 sm:mb-0" onClick={scrollToTop}>
            <img src={Logo} className="object-cover h-20 w-auto mr-5 ml-5" alt="JusaMusha Logo" />
          </a>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-white uppercase">Company</h2>
            <ul className="text-white font-medium">
              <li className="mb-4">
                <Link to="/" className="hover:underline" onClick={scrollToTop}>Home</Link>
              </li>
              <li className="mb-4">
                <Link to="/faq" className="hover:underline" onClick={scrollToTop}>FAQ</Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-start">
            <h2 className="mb-6 text-sm font-semibold text-white uppercase">Legal</h2>
            <ul className="text-white font-medium">
              <li className="mb-4">
                <Link to="/terms-and-conditions" className="text-white hover:underline" onClick={scrollToTop}>
                  Terms &amp; Conditions
                </Link>
              </li>
              <li className="mb-4">
                <Link to="/privacy_policy" className="hover:underline" onClick={scrollToTop}>Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white ml-5">
            <p className="pr-8 text-xl">Follow Us</p>
            <SocialMediaLink
              href="https://www.facebook.com/profile.php?id=61550738293751"
              logoSrc={FacebookLogo}
              altText="Facebook Logo"
              bgColorClass="hover:bg-amber"
            />
            <SocialMediaLink
              href="https://www.tiktok.com/@jusamusha"
              logoSrc={TikTokLogo}
              altText="TikTok Logo"
              bgColorClass="hover:bg-amber"
            />
            <SocialMediaLink
              href="https://twitter.com/JusaMusha"
              logoSrc={XLogo}
              altText="X Logo"
              bgColorClass="hover:bg-amber"
            />
          </ul>
        </div>
        <hr className="my-6 border-white sm:mx-auto dark:border-white lg:my-8" />
        <span className="block text-sm text-white sm:text-center dark:text-white">
          © {new Date().getFullYear()} <a href="https://bitwisesoftware.co.za/" className="hover:underline">Bitwise™</a>. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
