import React from "react";

const Faq = () => {
  return (
    <section className="py-10 mb-10 sm:py-6 lg:py-12 ">
      <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <a id="faq">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-navy sm:text-4xl lg:text-5xl">
              Frequently Asked Questions{" "}
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-navy">
              Welcome to our comprehensive FAQ guide for JusaMusha, the go-to
              platform for seamlessly recharging airtime and electricity between
              South Africa and Zimbabwe. Here, we provide answers to your most
              frequently asked questions, ensuring you have a smooth and
              hassle-free experience using our services.
            </p>
          </div>

          <div className="grid grid-cols-1 mt-12 md:mt-20 md:grid-cols-2 gap-y-16 gap-x-20">
            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-amber rounded-xl">
                <span className="text-2xl font-semibold text-white">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-navy">
                  How do I use JusaMusha?
                </p>
                <p className="mt-4 text-base text-navy">
                  Simply SMS JUSA to 45776 to get started, and follow our easy
                  steps to place your order.
                </p>
                <p className="mt-4 text-base text-navy">
                  There is no registration required, nor an app to download. We
                  use simple SMS technology to make your life easier.
                </p>
                <p className="mt-4 text-base text-navy">
                  And if you are making a cash payment, you do not need mobile
                  data use the service.
                </p>
                <p className="mt-4 text-base text-navy">
                  Standard SMS rates apply.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-amber rounded-xl">
                <span className="text-2xl font-semibold text-white">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-navy">
                  How do I make payment?
                </p>
                <p className="mt-4 text-base text-navy">
                  The easiest way to make payment is with a cash voucher, which
                  you can purchase from any shop in your area
                  (ask at your nearest local spaza shop, and participating major
                  retailers).
                </p>
                <p className="mt-4 text-base text-navy">
                  The voucher will have a  pin code, which you will need
                  to enter when prompted during the ordering process.
                </p>
                <p className="mt-4 text-base text-navy">
                  We also accept card and instant EFT payments.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-amber rounded-xl">
                <span className="text-2xl font-semibold text-white">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-navy">
                  Are there any special offers?
                </p>
                <p className="mt-4 text-base text-navy">
                  Yes, JusaMusha regularly offers discounts and promotions to
                  provide you with the best value for your recharges. Keep an
                  eye out for our exciting offers!
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-amber rounded-xl">
                <span className="text-3xl font-semibold text-white">?</span>
              </div>
              <div className="ml-4">
                <p className="text-xl font-semibold text-navy">
                  Are orders processed immediately?
                </p>
                <p className="mt-4 text-base text-navy">
                  Orders are processed immediately and you will receive a
                  confirmation SMS once your order has been processed.
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-12 md:mt-20">
            <div className="px-8 py-4 text-center bg-amber rounded-full">
              <p className="text-navy">
                Didn’t find the answer you're looking for?{" "}
                <a
                  href="https://wa.me/27681573844"
                  class="text-white transition-all duration-200 hover:underline"
                >
                  Contact us on WhatsApp
                </a>
              </p>
            </div>
          </div>
        </a>
      </div>
    </section>
  );
};

export default Faq;
