import React from "react";

const Privacy = () => {
  return (
    <section className="py-24 mt-24 sm:py-10 lg:py-12 ">
      <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <a id="privacy_policy">
        <div className="max-w-2xl mx-auto text-center sm:text-center md:text-center">
        <h2 className="text-3xl font-bold leading-tight text-navy sm:text-4xl lg:text-5xl">
              JUSAMUSHA
            </h2>
            <p className="mt-2 text-3xl underline leading-8 font-bold tracking-tight text-navy sm:text-4xl">
              Privacy Policy
            </p>
          </div>

          <div className="py-3">
          <p className="py-3 mt-6 text-lg leading-8 text-navy">
          <a
              href="https://bitwisesoftware.co.za"
              className="text-amber underline"
            >
              JUSAMUSHA
            </a> is a product of <a
              href="https://bitwisesoftware.co.za"
              className="text-amber underline"
            >
              Bitwise Software Solutions PTY LTD
            </a>. This Privacy Policy describes how we collect, use, and protect information through the use of our website. This policy is not applicable to any information collected offline or via e-mail. None of our main priorities is the privacy of our visitors and users. This Privacy Policy document outlines the types of information that are collected and recorded by JUSAMUSHA and how we use it.
          </p>
        </div>

        <div className="py-4">
          <h className="text-xl font-semibold text-navy">Log Files</h>
          <p className="text-lg leading-8 text-navy">
            JUSAMUSHA follows a standard procedure of using log files. These
            files log visitors when they access our services. This is a common
            practice among hosting companies and is used for analytics. The
            information collected by log files includes IP addresses, browser
            types, Internet Service Provider (ISP), date/time stamps, referring
            or exit pages, and possibly the number of clicks. None of this data
            is linked to personally identifiable information. We use this data
            to analyze trends, administer the site, track users’ movement, and
            gather demographic information.
          </p>
        </div>

        <div className="py-4">
          <h className="text-xl font-semibold text-navy">Cookies and Web Beacons</h>
          <p className="text-lg leading-8 text-navy">
            Like many other websites, JUSAMUSHA uses cookies. These cookies
            store information about visitors' preferences and the pages they
            access. This information helps us customize content based on your
            browser type and optimize your experience on our platform.
             For more information on cookies, please read the "Cookies" section
            from the Privacy Policy Generator.
          </p>
        </div>

        <div className="py-4">
          <h className="text-xl font-semibold text-navy">Privacy Policies</h>
          <p className="text-lg leading-8 text-navy">
            Third-party ad servers or networks may use technologies such as
            cookies, JavaScript, or web beacons on advertisements and links that
            appear on JUSAMUSHA. These are sent directly to your browser,
            automatically receiving your IP address when this happens. These
            technologies help measure the effectiveness of advertising campaigns
            and tailor advertisements to you.
            JUSAMUSHA has no control over third-party cookies and is not
            responsible for their use.
          </p>
        </div>

        <div className="py-4">
          <h className="text-xl font-semibold text-navy">Third-Party Privacy Policies</h>
          <p className="text-lg leading-8 text-navy">
            JUSAMUSHA’s Privacy Policy does not extend to other advertisers or
            websites. We advise you to consult their privacy policies for more
            detailed information on how they handle your data.
            You can disable cookies through your browser settings. For more
            information, visit the respective browser’s website.
          </p>
        </div>

        <div className="py-4">
          <h className="text-xl font-semibold text-navy">Children's Information</h>
          <p className="text-lg leading-8 text-navy">
            Protecting children’s privacy is a top priority for us. We encourage
            parents to monitor and guide their children’s online activities.
            JUSAMUSHA does not knowingly collect personally identifiable
            information from children under 13. If you believe your child has
            provided such information on our platform, please contact us
            immediately, and we will take prompt action to remove it from our
            records.
          </p>
        </div>

        <div className="py-4">
          <h className="text-xl font-semibold text-navy">Online Privacy Policy Only</h>
          <p className="text-lg leading-8 text-navy">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website and users of JUSAMUSHA. It does
            not apply to any information collected offline or through other
            channels.
          </p>
        </div>

        <div className="py-4">
          <h className="text-xl font-semibold text-navy">Consent</h>
          <p className="text-lg leading-8 text-navy">
            By using our services, you consent to our Privacy Policy and agree
            to its terms.
          </p>
        </div>
      </a>
      </div>
    </section>
  );
};

export default Privacy;
